import { BaseSvg } from 'icons';

const ArrowIcon = ({ fill = '#CD0D32', height = '12px', width = '12px', ...props }) =>
  BaseSvg({
    alt: 'Arrow Icon',
    height,
    width,
    svg: `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><defs><style>.cls-1{fill:${fill};}</style></defs><path class="cls-1" fill-rule="evenodd" d="M.769 5.166h8.615L6.38 2.268a.725.725 0 0 1 0-1.05.79.79 0 0 1 1.088 0l4.316 4.165a.503.503 0 0 1 .053.055l.019.024.024.034.02.031c0 .012.014.022.02.031l.016.034c.006.01.012.021.016.033a.21.21 0 0 1 .012.032c0 .013.01.024.013.035a.303.303 0 0 1 .009.034c0 .013 0 .026.01.037a.477.477 0 0 1 0 .072.615.615 0 0 1 0 .141.3.3 0 0 1 0 .032v.04a.319.319 0 0 1-.01.037.303.303 0 0 1-.022.069.32.32 0 0 1-.012.034c0 .01-.01.02-.016.032-.006.011-.01.023-.016.033l-.02.03-.02.032a.319.319 0 0 1-.026.033l-.017.026c-.018.018-.034.037-.053.055L7.468 10.6c-.3.29-.788.29-1.088 0-.3-.29-.3-.76 0-1.05l3.004-2.9H.769A.756.756 0 0 1 0 5.907c0-.41.344-.742.769-.742"/></svg>`,
    ...props,
  });

export default ArrowIcon;
