import { BaseSvg } from 'icons';

const VariantsIcon = ({ fill = '#b2b7c1', height = '30px', width = '30px', ...props }) =>
  BaseSvg({
    alt: 'Variants Icon',
    height,
    width,
    svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><defs><style>.cls-1{fill: ${fill} ;}</style></defs><path class="cls-1" d="M45,0A45,45,0,1,0,90,45,45.05,45.05,0,0,0,45,0Zm0,86.54A41.54,41.54,0,1,1,86.54,45,41.59,41.59,0,0,1,45,86.54Z"/><path class="cls-1" d="M74,31.12a1.39,1.39,0,0,0-2-.07,11,11,0,0,1-4,2.33l-7.49-7.5a1.4,1.4,0,1,0-2,2l6.21,6.21a23,23,0,0,1-4.77,0,1.4,1.4,0,1,0-.28,2.78,27.17,27.17,0,0,0,2.79.15c3.78,0,8.17-.83,11.47-3.9A1.4,1.4,0,0,0,74,31.12Z"/><path class="cls-1" d="M57.21,35.84c0-.11-3.92-11.61,1.87-17.75a1.4,1.4,0,0,0-2-1.92C50.17,23.45,54.3,36,54.56,36.74c.14.45,2.46,8.16-2.12,13.81l-13-13a12.24,12.24,0,0,1,3.72-2L50.56,43a1.39,1.39,0,0,0,1,.41,1.36,1.36,0,0,0,1-.41,1.39,1.39,0,0,0,0-2L46.48,34.9A18.8,18.8,0,0,1,50.7,35a1.4,1.4,0,1,0,.38-2.77,20.49,20.49,0,0,0-7.64.36,1.39,1.39,0,0,0-.44.13,14.94,14.94,0,0,0-6.56,3.78h0c-6.92,6.88-3.83,17.09-3.67,17.6l0,0c0,.11,3.91,11.61-1.87,17.75a1.4,1.4,0,0,0,.06,2,1.42,1.42,0,0,0,1,.38,1.39,1.39,0,0,0,1-.44c6.86-7.28,2.74-19.8,2.48-20.57-.14-.44-2.44-8.08,2-13.72l13,13a12.21,12.21,0,0,1-3.68,1.94L39.36,47a1.4,1.4,0,0,0-2,2l6.08,6.08A18.86,18.86,0,0,1,39.3,55a1.4,1.4,0,1,0-.38,2.77,20.83,20.83,0,0,0,2.95.2A16.24,16.24,0,0,0,53.44,53.6l0,0s0,0,0,0l.06,0c6.93-6.9,3.82-17.12,3.66-17.61A.08.08,0,0,0,57.21,35.84Z"/><path class="cls-1" d="M30.34,62.34,24.1,56.09A22.55,22.55,0,0,1,30,55.93a1.4,1.4,0,1,0,.27-2.78,21.88,21.88,0,0,0-9.43.9h0a13.65,13.65,0,0,0-4.8,2.84,1.4,1.4,0,0,0-.07,2A1.39,1.39,0,0,0,18,59,10.59,10.59,0,0,1,21,57l7.32,7.33a1.4,1.4,0,1,0,2-2Z"/></svg>`,
    ...props,
  });

export default VariantsIcon;
