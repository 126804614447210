import { BaseSvg } from 'icons';

const ListIcon = ({ fill = 'currentColor', height = '12px', width = '12px', ...props }) =>
  BaseSvg({
    alt: 'List Icon',
    height,
    width,
    svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 28.88"><defs><style>.cls-1{fill:${fill};}</style></defs><title>icon-modellist-empty-red</title><path class="cls-1" d="M0,0V28.88H22V8.94H13.75a.72.72,0,0,1-.69-.69V0ZM14.44.69V7.56h6.87ZM6.81,10a.69.69,0,0,1,.58,1.15L5,13.86a.69.69,0,0,1-1,.06l0,0-1-1a.69.69,0,0,1,0-1,.68.68,0,0,1,1,0l0,0,.52.52,1.92-2.21A.7.7,0,0,1,6.81,10ZM8.87,12h9.69a.69.69,0,1,1,0,1.38H8.94a.7.7,0,0,1-.72-.66A.68.68,0,0,1,8.87,12ZM6.81,15.8a.69.69,0,0,1,.76.61.66.66,0,0,1-.18.54L5,19.7a.68.68,0,0,1-1,.06l0,0-1-1a.69.69,0,0,1,0-1,.67.67,0,0,1,1,0h0l.52.52L6.36,16A.67.67,0,0,1,6.81,15.8Zm2.06,2.08h9.69a.68.68,0,0,1,.7.67.69.69,0,0,1-.68.7H8.94a.69.69,0,0,1-.72-.66A.67.67,0,0,1,8.87,17.88ZM6.81,21.65a.68.68,0,0,1,.76.6.66.66,0,0,1-.18.54L5,25.54a.67.67,0,0,1-1,.06l0,0-1-1a.7.7,0,0,1,0-1,.69.69,0,0,1,1,0l0,0,.52.51,1.92-2.2A.66.66,0,0,1,6.81,21.65Zm2.06,2.07h9.69a.69.69,0,0,1,.7.68.68.68,0,0,1-.68.69H8.94a.69.69,0,1,1-.07-1.37Z"/></svg>`,
    ...props,
  });

export default ListIcon;
