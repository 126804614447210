export const primarySites = [
  'Abdomen',
  'Abdomen pelvis',
  'Abdominal wall',
  'Acetabulum',
  'Adenoid',
  'Adipose',
  'Adrenal',
  'Alveolar ridge',
  'Amniotic fluid',
  'Ampulla of vater',
  'Anal canal',
  'Anal sphincter',
  'Ankle',
  'Anorectum',
  'Antecubital fossa',
  'Anterior mediastinum',
  'Antrum distal',
  'Anus',
  'Aorta',
  'Aortic body',
  'Appendix',
  'Aqueous fluid',
  'Arm',
  'Artery',
  'Ascending colon',
  'Ascending colon hepatic flexure',
  'Ascites peritoneum',
  'Auditory canal',
  'Autonomic nervous system',
  'Axilla',
  'Axillary',
  'Axillary lymph nodes',
  'Axillary brachial',
  'Back',
  'Base of tongue',
  'Bile duct',
  'Bladder',
  'Blood',
  'Blood vessel',
  'Body of pancreas',
  'Bone',
  'Bone marrow',
  'Bowel',
  'Brain',
  'Brain stem',
  'Breast',
  'Broad ligament',
  'Bronchial',
  'Bronchiole',
  'Bronchus',
  'Bronchus and lung',
  'Brow',
  'Buccal cavity',
  'Buccal mucosa',
  'Buttock',
  'Calcaneus',
  'Calf',
  'Capillary',
  'Cardia proximal',
  'Carina',
  'Carotid artery',
  'Carotid body',
  'Carpus',
  'Cartilage',
  'Cecum',
  'Celiac axis retrocrural',
  'Celiac axis retrocrural upper para aortic',
  'Central line catheter',
  'Central nervous system',
  'Cerebellum',
  'Cerebral cortex',
  'Cerebrospinal fluid',
  'Cerebrum',
  'Cervical',
  'Cervical lymph nodes',
  'Cervical spine',
  'Cervical vertebra',
  'Cervical thoracic lumbar spine',
  'Cervix',
  'Chest',
  'Chest wall',
  'Chest abdomen pelvis',
  'Chest pelvis',
  'Chin',
  'Clavicle',
  'Clitoris',
  'Coccyx',
  'Colon',
  'Colon and rectum',
  'Colon mucosa only',
  'Common duct',
  'Common pelvic',
  'Conjunctiva',
  'Connective tissue',
  'Cranial nerve',
  'Descending colon',
  'Diaphragm',
  'Distant lymph node',
  'Distant metastasis',
  'Duodenum',
  'Ear',
  'Ear canal',
  'Ear pinna external',
  'Effusion',
  'Elbow',
  'Endocrine gland',
  'Epididymis',
  'Epidural',
  'Epitrochlear lymph nodes',
  'Epitrochlear brachial',
  'Esophagus',
  'Esophagus mucosa only',
  'Ethmoid bone',
  'External internal iliac',
  'Extra adrenal site',
  'Extrahepatic bile duct',
  'Extremities',
  'Eye',
  'Fallopian tube',
  'Femoral',
  'Femoral lymph nodes',
  'Femur',
  'Fibula',
  'Fibula head',
  'Finger',
  'Floor of mouth',
  'Fluid',
  'Foot',
  'Forearm',
  'Forehead',
  'Foreskin',
  'Frontal bone',
  'Frontal lobe',
  'Fundus body',
  'Gallbladder',
  'Ganglia',
  'Gastroesophageal junction',
  'Gastrointestinal abdominal',
  'Genitourinary',
  'Groin',
  'Gum',
  'Hand',
  'Hard palate',
  'Head neck',
  'Head face or neck nos',
  'Head of pancreas',
  'Head neck chest abd pelvis',
  'Heart',
  'Hepatic duct',
  'Hepatic flexure',
  'Hilar',
  'Hilar lymph nodes',
  'Hilum',
  'Hip',
  'Humerus',
  'Hypopharynx',
  'Ileum',
  'Iliac',
  'Iliac common',
  'Iliac external',
  'Ilium',
  'Infraclavicular',
  'Infraclavicular axillary pectoral',
  'Inguinal',
  'Inguinal femoral',
  'Inguinal femoral iliac',
  'Intestine',
  'Intraocular',
  'Ischium',
  'Jaw',
  'Jejunum',
  'Joints',
  'Kidney',
  'Knee',
  'Lacrimal bone',
  'Lacrimal gland',
  'Large bowel',
  'Large intestine',
  'Laryngopharynx',
  'Larynx',
  'Leg',
  'Leptomeninges',
  'Ligament',
  'Lip',
  'Liver',
  'Liver and intrahepatic duct',
  'Liver spleen',
  'Lower para aortic',
  'Lumbar spine',
  'Lumbar vertebra',
  'Lung',
  'Lymph nodes',
  'Lymph nodes axilla',
  'Lymph nodes cervical',
  'Lymph nodes distant',
  'Lymph nodes epitrochlear',
  'Lymph nodes femoral',
  'Lymph nodes hilar',
  'Lymph nodes iliac',
  'Lymph nodes iliac common',
  'Lymph nodes iliac external',
  'Lymph nodes inguinal',
  'Lymph nodes int mammary',
  'Lymph nodes mammary',
  'Lymph nodes mediastinal',
  'Lymph nodes mesenteric',
  'Lymph nodes occipital',
  'Lymph nodes para aortic',
  'Lymph nodes paracaval',
  'Lymph nodes parotid',
  'Lymph nodes pelvic',
  'Lymph nodes periportal',
  'Lymph nodes popliteal',
  'Lymph nodes regional',
  'Lymph nodes retroperitoneal',
  'Lymph nodes scalene',
  'Lymph nodes splenic',
  'Lymph nodes subclavicular',
  'Lymph nodes submandibular',
  'Lymph nodes supraclav',
  'Lymphoma',
  'Mammary gland',
  'Mandible',
  'Maxilla',
  'Mediastinal',
  'Mediastinal intra thoracic',
  'Mediastinal lymph nodes',
  'Mediastinal soft tissue',
  'Mediastinum',
  'Mesenteric',
  'Mesenteric lymph nodes',
  'Mesentery',
  'Mesothelium',
  'Metacarpus',
  'Metatarsus',
  'Mini mantle',
  'Mouth',
  'Muscle',
  'Nails',
  'Nasal bone',
  'Nasal cavity',
  'Nasal soft tissue',
  'Nasopharynx',
  'Neck',
  'Neck chest',
  'Neck chest abdomen pelvis',
  'Nerve',
  'Nerves cranial',
  'No known extranodal involvement',
  'No known nodal involvement',
  'Not applicable',
  'Occipital',
  'Occipital bone',
  'Occipital lymph nodes',
  'Ocular orbits',
  'Omentum',
  'Oral cavity',
  'Oral cavity mucosa only',
  'Oral tongue',
  'Orbit',
  'Orbital',
  'Oropharyngeal soft tissue',
  'Oropharynx',
  'Other extranodal site',
  'Other nodal',
  'Other specify',
  'Ovary',
  'Pa lat',
  'Palate',
  'Pancreas',
  'Para aortic',
  'Paraaortic lymph nodes',
  'Paraspinal ganglion',
  'Parathyroid',
  'Paratracheal',
  'Parietal bone',
  'Parotid',
  'Parotid gland',
  'Patella',
  'Pectoral',
  'Pelvis',
  'Penis',
  'Peri orbital soft tissue',
  'Perianus',
  'Pericardium',
  'Peripheral blood',
  'Peritoneal cavity',
  'Peritoneum',
  'Pharynx',
  'Pineal gland',
  'Pituitary gland',
  'Placenta',
  'Pleura',
  'Pleura pleural effusion',
  'Popliteal',
  'Popliteal fossa',
  'Popliteal lymph nodes',
  'Porta caval',
  'Porta hepatis',
  'Pre auricular',
  'Primary tumor',
  'Prostate',
  'Pubis',
  'Pulmonary system',
  'Pylorus',
  'Radius',
  'Rectosigmoid junction',
  'Rectum',
  'Regional cutaneous or subcutaneous tissue includes satellite in transit metastasis',
  'Regional lymph node',
  'Retina',
  'Retro orbital area',
  'Retroperitoneal',
  'Retroperitoneal lymph nodes',
  'Ribs',
  'Round ligament',
  'Sacrum',
  'Salivary gland',
  'Scalp',
  'Scapula',
  'Scrotum',
  'Seminal vesicle',
  'Shoulder',
  'Sigmoid colon',
  'Sinus',
  'Sinus es maxillary',
  'Skeletal muscle',
  'Skin',
  'Skull',
  'Small bowel mucosa only',
  'Small intestine',
  'Soft tissue muscle ligaments subcutaneous',
  'Sphenoid bone',
  'Spinal cord',
  'Spine',
  'Spine femur',
  'Spine femur radius',
  'Spleen',
  'Splenic',
  'Splenic flexure',
  'Splenic hilar',
  'Splenic lymph nodes',
  'Sternum',
  'Stomach',
  'Stomach mucosa only',
  'Subcutaneous tissue',
  'Submandibular',
  'Submandibular lymph nodes',
  'Supraclavicular',
  'Supraclavicular lymph nodes',
  'Synovium',
  'Systemic',
  'Tail of pancreas',
  'Tarsus',
  'Temporal bone',
  'Tendon',
  'Testes',
  'Testicle',
  'Thigh',
  'Thoracic',
  'Thoracic spine',
  'Thoracic vertebra',
  'Thoracic lumbar spine',
  'Thorax',
  'Throat',
  'Thymus',
  'Thyroid',
  'Thyroid gland',
  'Tibia',
  'Toe',
  'Tongue',
  'Tongue base of tongue',
  'Tonsil',
  'Tonsil pharyngeal',
  'Trachea',
  'Trachea major bronchi',
  'Transverse colon',
  'Trunk',
  'Trunk superficial',
  'Ulna',
  'Umbilical cord',
  'Unknown',
  'Upper extremity',
  'Upper para aortic',
  'Ureter',
  'Urethra',
  'Urinary tract',
  'Uterus',
  'Uvula',
  'Vagina',
  'Vas deferens',
  'Vein',
  'Vena cava inferior superior',
  'Vertebra',
  'Vertebral column',
  'Vulva',
  'Waldeyers ring',
  'Whole body',
  'Wrist',
  'Zygomatic bone',
];

// Used for the /wrangle-cde/sheet-id/tab-name endpoint
export const toExcelHeaders = {
  'breast cancer': 'breast_data_entry_options',
  'colorectal cancer': 'colon/rectum_data_entry_options',
  'diffuse midline glioma': 'diffuse_midline_glioma_data_entry_options',
  'embryonal tumor': 'embryonal_tumor_data_entry_options',
  'esophageal cancer': 'esophageal_data_entry_options',
  "ewing's sarcoma": 'ewing_sarcoma_data_entry_options',
  'extrahepatic bile duct cancer': '?',
  glioblastoma: 'glioblastoma_data_entry_options',
  'head and neck cancer': '?',
  'hematologic cancer': '?',
  hepatoblastoma: 'pediatric_liver_cancer_data_entry_options_b',
  'hepatocellular carcinoma':
    'heptatocellular_carcinoma_and_intrahepatic_bile_duct_cancer_data_entry_options_a',
  'intrahepatic bile duct cancer':
    'heptatocellular_carcinoma_and_intrahepatic_bile_duct_cancer_data_entry_options_b',
  'low grade glioma': 'lower_grade_glioma_data_entry_options',
  'lung cancer': 'lung_data_entry_options',
  medulloblastoma: 'medulloblastoma_data_entry_options',
  melanoma: 'melanoma_data_entry_options',
  neuroblastoma: 'neuroblastoma_data_entry_options',
  osteosarcoma: 'osteosarcoma_data_entry_options',
  'ovarian cancer': '?',
  'pancreatic cancer': 'pancreas_data_entry_options',
  'pediatric hepatocellular carcinoma': 'pediatric_liver_cancer_data_entry_options_a',
  'prostate cancer': '?',
  'rare cancers': '?',
  'kidney cancer': '?',
  rhabdomyosarcoma: 'rhabdomyosarcoma_data_entry_options',
  'stomach cancer': 'stomach_data_entry_options',
  'thyroid cancer': '?',
  'wilms tumor': 'wilms_tumor_data_entry_options',
  'ampulla of vater': 'ampulla_of_vater_cancer_data_entry_options',
};

// Used for the /wrangle-cde/sheet-id/tab-name endpoint
export const toExcelRowNumber = {
  'histological type': 0,
  'clinical stage grouping': 13,
  'site of sample acquisition': 4,
  'tumor histological grade': 23,
};

// this is generated from /wrangle-cde/sheet-id/tab-name endpoint
export const clinicalTumorDiagnosisDependent = {
  'histological type': {
    'breast cancer': [
      'Infiltrating ductal carcinoma',
      'Infiltrating lobular carcinoma',
      'Mucinous carcinoma',
      'Medullary carcinoma',
      'Metaplastic ductal carcinoma',
      'Infiltrating carcinoma (NOS)',
      'Mixed histology',
      'Other',
      'Unknown',
    ],
    'colorectal cancer': [
      'Adenocarcinoma',
      'Mucinous adenocarcinoma',
      'Signet ring cell carcinoma',
      'Medullary carcinoma',
      'Micropapillary carcinoma',
      'Serrated adenocarcinoma',
      'Large cell neuroendocrine carcinoma',
      'Small cell neuroendocrine carcinoma',
      'Neuroendocrine carcinoma (poorly differentiated)',
      'Squamous cell carcinoma',
      'Tubular adenoma',
      'Tubulovillous adenoma',
      'Adenosquamous carcinoma',
      'Undifferentiated carcinoma',
      'Carcinoma, type cannot be determined',
      'Other',
    ],
    'diffuse midline glioma': [],
    'embryonal tumor': [
      'CNS atypical teratoid/rhabdoid tumor',
      'CNS ganglioneuroblastoma',
      'CNS neuroblastoma',
      'ETMR',
      'Medulloepithelioma',
      'NOS',
      'Other',
    ],
    'endometrial cancer': [
      'Endometrioid carcinoma, NOS',
      'Endometrioid carcinoma with squamous differentiation',
      'Endometrioid carcinoma, villoglandular variant',
      'Endometrioid carcinoma with secretory differentiation',
      'Endometrioid carcinoma, other variant',
      'Serous endometrial intraepithelial carcinoma',
      'Serous carcinoma',
      'Carcinosarcoma (malignant mixed Müllerian tumor)',
      'Mucinous carcinoma',
      'Clear cell carcinoma',
      'Small cell neuroendocrine carcinoma',
      'Large cell neuroendocrine carcinoma',
      'Mixed cell carcinoma',
      'Undifferentiated carcinoma',
      'De-differentiated carcinoma',
      'Other',
    ],
    'esophageal cancer': [
      'Esophagus adenocarcinoma (NOS)',
      'Other histology',
      'Esophagus squamous cell carcinoma',
      'Unknown',
      'Other histology',
    ],
    "ewing's sarcoma": [],
    'extrahepatic bile duct cancer': [],
    glioblastoma: ['Giant cell glioblastoma', 'Gliosarcoma', 'NOS', 'Other'],
    'head and neck cancer': [],
    'head and neck cancer: lip and oral cavity': [
      'Acantholytic squamous cell carcinoma',
      'Acinic cell carcinoma',
      'Adenocarcinoma, NOS',
      'Adenoid cystic carcinoma',
      'Adenosquamous carcinoma',
      'Basal cell adenocarcinoma',
      'Basaloid squamous cell carcinoma',
      'Carcinoma cuniculatum',
      'Carcinosarcoma',
      'Clear cell carcinoma',
      'Epithelial-myoepithelial carcinoma',
      'Intraductal carcinoma',
      'Lymphoepithelial carcinoma',
      'Moderately differentiated neuroendocrine carcinoma',
      'Mucoepidermoid carcinoma',
      'Mucosal melanoma',
      'Myoepithelial carcinoma',
      'Oncocytic carcinoma',
      'Other',
      'Papillary squamous cell carcinoma',
      'Polymorphous adenocarcinoma, classic',
      'Polymorphous adenocarcinoma, cribriform',
      'Poorly differentiated neuroendocrine carcinoma, large cell',
      'Poorly differentiated neuroendocrine carcinoma, small cell',
      'Salivary duct carcinoma',
      'Secretory carcinoma',
      'Spindle cell squamous cell carcinoma',
      'Squamous cell carcinoma, conventional',
      'Verrucous squamous cell carcinoma',
      'Well differentiated neruoendocrine carcinoma',
    ],
    'head and neck cancer: pharynx': [
      'Acantholytic squamous cell carcinoma',
      'Adenosquamous carcinoma',
      'Basaloid squamous cell carcinoma',
      'HPV status unknown',
      'HPV-mediated squamous cell carcinoma',
      'HPV-unrelated squamous cell carcinoma',
      'Hypopharyngeal squamous cell carcinoma',
      'Large cell neuroendocrine carcinoma, HPV -',
      'Large cell neuroendocrine carcinoma, HPV +',
      'Lymphoepithelial carcinoma',
      'Minor salivary gland carcinoma',
      'Mucosal melanoma',
      'Nasopharyngeal papillary adenocarcinoma',
      'Oropharyngeal squamous cell carcinoma,',
      'Other',
      'Papillary squamous cell carcinoma',
      'Small cell neuroendocrine carcinoma, HPV -',
      'Small cell neuroendocrine carcinoma, HPV +',
      'Spindle cell squamous cell carcinoma',
      'Squamous cell carcinoma, keratinizing',
      'Squamous cell carcinoma, nonkeratinizing',
      'Verrucous squamous cell carcinoma',
    ],
    'head and neck cancer: larynx': [
      'Acantholytic squamous cell carcinoma',
      'Adenocarcinoma, NOS',
      'Adenoid cystic carcinoma',
      'Adenosquamous carcinoma',
      'Basaloid squamous cell carcinoma',
      'Lymphoepithelial carcinoma',
      'Minor salivary gland carcinoma',
      'Moderately differentiated neuroendocrine carcinoma',
      'Mucoepidermoid carcinoma',
      'Mucosal melanoma',
      'Neuroendocrine carcinoma',
      'Other',
      'Papillary squamous cell carcinoma',
      'Poorly differentiated neuroendocrine carcinoma, large cell',
      'Poorly differentiated neuroendocrine carcinoma, small cell',
      'Spindle cell squamous cell carcinoma',
      'Squamous cell carcinoma, keratinizing',
      'Squamous cell carcinoma, nonkeratinizing',
      'Verrucous squamous cell carcinoma',
      'Well differentiated neruoendocrine carcinoma',
    ],
    'head and neck cancer: paranasal sinuses and nasal cavity': [
      'Acinic cell carcinoma',
      'Adenocarcinoma, NOS',
      'Adenoid cystic carcinoma',
      'Adenosquamous carcinoma',
      'Basaloid squamous cell carcinoma',
      'Biphenotypic sinonasal sarcoma',
      'Clear cell carcinoma',
      'Epithelial-myoepithelial carcinoma',
      'Intestinal adenocarcinoma, colonic pattern',
      'Intestinal adenocarcinoma, mixed pattern',
      'Intestinal adenocarcinoma, mucinous pattern',
      'Intestinal adenocarcinoma, papillary pattern',
      'Intestinal adenocarcinoma, solid pattern',
      'Lymphoepithelial carcinoma',
      'Moderately differentiated neuroendocrine carcinoma',
      'Mucoepidermoid carcinoma',
      'Mucosal melanoma',
      'Non-intestinal adenocarcinoma',
      'NUT carcinoma',
      'Papillary squamous cell carcinoma',
      'Polymorphous adenocarcinoma, classic',
      'Polymorphous adenocarcinoma, cribriform',
      'Poorly differentiated neuroendocrine carcinoma, large cell',
      'Poorly differentiated neuroendocrine carcinoma, small cell',
      'Salivary duct carcinoma',
      'Secretory carcinoma',
      'Sinonasal undifferentiated carcinoma',
      'Spindle cell squamous cell carcinoma',
      'Squamous cell carcinoma, keratinizing',
      'Squamous cell carcinoma, nonkeratinizing',
      'Verrucous squamous cell carcinoma',
      'Well-differentiated neuroendocrine carcinoma',
    ],
    'head and neck cancer: salivary glands': [
      'Acinic cell carcinoma',
      'Adenocarcinoma, NOS',
      'Adenoid cystic carcinoma',
      'Basal cell adenocarcinoma',
      'Carcinosarcoma',
      'Clear cell carcinoma',
      'Epithelial-myoepithelial carcinoma',
      'Intraductal carcinoma',
      'Lymphoepithelial carcinoma',
      'Mucoepidermoid carcinoma',
      'Myoepithelial carcinoma',
      'Oncocytic carcinoma',
      'Other',
      'Polymorphous adenocarcinoma, classic',
      'Polymorphous adenocarcinoma, cribriform',
      'Poorly differentiated carcinoma, large cell neuroendocrine',
      'Poorly differentiated carcinoma, small cell neuroendocrine',
      'Poorly differentiated carcinoma, undifferentiated',
      'Salivary duct carcinoma',
      'Sebaceous adenocarcinoma',
      'Secretory carcinoma',
      'Squamous cell carcinoma, primary',
    ],
    'hematologic cancer': [],
    hepatoblastoma: [
      'Fetal/embryonal',
      'Mesenchymal/macrotrabecular',
      'Mixed epithelial',
      'Small cell undifferentiated',
      'Well differentiated fetal',
    ],
    'hepatocellular carcinoma': [
      'Hepatocellular carcinoma',
      'Fibrolamellar hepatocellular carcinoma',
    ],
    'intrahepatic bile duct cancer': [
      'Intrahepatic cholangiocarcinoma',
      'Combined hepatocellular-cholangiocarcinoma',
      'Intraductal papillary neoplasm with an associated invasive carcinoma',
      'Mucinous cystic neoplasm with an associated invasive carcinoma',
      'Large cell neuroendocrine carcinoma',
      'Small cell neuroendocrine carcinoma',
      'Poorly differentiated neuroendocrine carcinoma',
      'Other',
    ],
    'low grade glioma': [
      'Anaplastic astrocytoma',
      'Anaplastic ganglioglioma',
      'Anaplastic oligodendroglioma',
      'Anaplastic pleomorphic xanthoastrocytoma',
      'Oligodendroglioma',
      'Pilocytic astrocytoma',
      'NOS',
      'Other',
    ],
    'lung cancer': [
      'Acinar adenocarcinoma',
      'Lepidic adenocarcinoma',
      'Papillary adenocarcinoma',
      'Solid adenocarcinoma',
      'Minimally invasive adenocarcinoma, mucinous',
      'Minimally invasive adenocarcinoma, nonmucinous',
      'Micropapillary adenocarcinoma',
      'Enteric adenocarcinoma',
      'Colloid adenocarcinoma',
      'Fetal adenocarcinoma',
      'Signet ring adenocarcinoma',
      'Adenocarcinoma in situ, mucinous',
      'Adenocarcinoma in situ, nonmucinous',
      'Adenocarcinoma (NOS)',
      'Keratinizing squamous cell carcinoma',
      'Nonkeritinizing squamous cell carcinoma',
      'Adenosquamous carcinoma',
      'Basaloid squamous cell carcinoma',
      'Squamous cell carcinoma (NOS)',
      'Other',
      'Unknown',
    ],
    medulloblastoma: [
      'Classic medulloblastoma',
      'Desmoplastic/nodular medulloblatoma',
      'Indeterminate medulloblastoma',
      'Large cell/anaplastic medulloblastoma',
      'Medulloblastoma with extensive nodularity',
      'NOS',
      'Other',
    ],
    melanoma: [
      'Superficial spreading melanoma',
      'Nodular melanoma',
      'Lentigo maligna melanoma',
      'Acral lentiginous melanoma',
      'Desmoplastic melanoma',
      'Melanoma arising from blue nevus',
      'Melanoma arising in a giant congenital nevus',
      'Nevoid melanoma',
      'Melanoma, NOS',
      'Other',
    ],
    neuroblastoma: ['Favorable', 'Unfavorable', 'Unknown'],
    osteosarcoma: ['Osteoblastic', 'Chondroblastic', 'Fibroblastic', 'Telangiectatic'],
    other: [],
    'ovarian cancer': [
      'Serous tubal intraepithelial carcinoma (STIC)',
      'Serous borderline tumor/atypical proliferative serous tumor',
      'Serous borderline tumor/atypical proliferative serous tumor with microinvasion',
      'Serous borderline tumor-micropapillary variant/noninvasive low-grade serous carcinoma',
      'Low-grade serous carcinoma',
      'High grade serous carcinoma',
      'Mucinous borderline tumor/atypical proliferative serous tumor',
      'Mucinous borderline tumor/atypical proliferative mucinous tumor with intraepithelial carcinoma',
      'Mucinous borderline tumor/atypical proliferative mucinous tumor with microinvasion',
      'Mucinous carcinoma',
      'Seromucinous borderline tumor/atypical proliferative seromucinous tumor',
      'Seromucinous borderline tumor/atypical proliferative seromucinous tumor with microinvasion',
      'Seromucinous carcinoma',
      'Endometrioid borderline tumor/atypical proliferative endometrioid tumor',
      'Endometrioid borderline tumor/atypical proliferative endometrioid tumor with microinvasion Endometrioid carcinoma',
      'Clear cell borderline tumor/atypical proliferative clear cell tumor',
      'Clear cell carcinoma',
      'Borderline Brenner tumor/atypical proliferative Brenner tumor',
      'Malignant Brenner tumor',
      'Mixed Epithelial Borderline Tumor',
      'Mixed epithelial carcinoma',
      'Granulosa cell tumor, adult type',
      'Granulosa cell tumor, juvenile type',
      'Sertoli-Leydig cell tumor',
      'Other sex cord-stromal tumor',
      'Dysgerminoma',
      'Yolk sac tumor',
      'Embryonal carcinoma',
      'Choriocarcinoma, non-gestational',
      'Immature teratoma',
      'Carcinoma arising in a teratoma',
      'Mixed malignant germ cell tumor',
      'Small cell carcinoma, pulmonary type',
      'Small cell carcinoma, hypercalcemic type',
      'Squamous cell carcinoma',
      'Transitional cell carcinoma',
      'Undifferentiated carcinoma',
      'Carcinoma, subtype cannot be determined',
      'Undifferentiated Carcinoma',
      'Carcinosarcoma (malignant mixed Müllerian tumor)',
      'Other',
    ],
    'pancreatic cancer': [
      'Acinar cell carcinoma',
      'Acinar cell cystadenocarcinoma',
      'Adenocarcinoma ductal type',
      'Hepatoid carcinoma',
      'High-grade neuroendocrine carcinoma (large cell type)',
      'High-grade neuroendocrine carcinoma (small cell type)',
      'Inflammatory myofibroblastic tumor',
      'Intraductal papillary mucinous neoplasm (IPMN, main duct/mixed)',
      'Intraductal papillary mucinous neoplasm (IPMN, side duct)',
      'Invasive adenocarcinoma arising from IPMN',
      'Invasive carcinoma arising in MCN',
      'Medullary carcinoma',
      'Mixed acinar-ductal carcinomas',
      'Mixed acinar-neuroendocrine carcinoma',
      'Mixed ductal-neuroendocrine carcinoma',
      'Mucinous cystic neoplasms (MCN)',
      'Pancreas colloid (mucinous non-cystic) carcinoma',
      'Pancreatoblastoma',
      'Serous cystic adenoma (SCA)',
      'Signet ring cell carcinoma',
      'Solid pseudopapillary neoplasm',
      'Undifferentiated (anaplastic) carcinoma',
      'Undifferentiated carcinoma with osteoclast-like giant cells',
      'Other',
      'Unknown',
    ],
    'pediatric hepatocellular carcinoma': ['Fibrolamellar', 'Hepatocellular NOS'],
    'prostate cancer': [
      'Acinar adenocarcinoma',
      'Ductal adenocarcinoma',
      'Isolated intraductal carcinoma',
      'Neuroendocrine prostate carcinoma (NEPC)',
      'Small-cell neuroendocrine carcinoma',
      'Other',
    ],
    'rare cancers': [
      'Adenocarcinoma',
      'Chordoma',
      'Duodenal gastrinoma',
      'Epithelial sarcoma',
      'Epithelioid sarcoma',
      'Extrahepatic cholangiocarcinoma',
      'Intraductal papillary neoplasm',
      'Malignant spindle cell neoplasm',
      'Small intestine cancer',
      'Spindle cell sarcoma',
      'Tubulovillous adenoma',
    ],
    'kidney cancer': [
      'Acquired cystic disease associated renal cell carcinoma',
      'Chromophobe renal cell carcinoma',
      'Clear cell papillary renal cell carcinoma',
      'Clear cell renal cell carcinoma',
      'Collecting duct carcinoma',
      'Hereditary leiomyomatosis and renal cell carcinoma-associated renal cell carcinoma',
      'MiT family translocation renal cell carcinoma ',
      'Mucinous tubular and spindle renal cell carcinoma',
      'Multilocular cystic clear cell renal cell neoplasm of low malignant potential',
      'Papillary renal cell carcinoma ',
      'Papillary renal cell carcinoma, Type 1',
      'Papillary renal cell carcinoma, Type 2',
      'Renal cell carcinoma, unclassified',
      'Renal medullary carcinoma',
      'Succinate dehydrogenase (SDH) deficient renal cell carcinoma',
      't(6;11) renal cell carcinoma',
      'Tubulocystic renal cell carcinoma',
      'Xp11 translocation renal cell carcinoma',
      'Other',
    ],
    rhabdomyosarcoma: ['Alveolar', 'Embryonal', 'Other'],
    'stomach cancer': [
      'Adenosquamous carcinoma',
      'Carcinoma with lymphoid stroma (medullary carcinoma)',
      'Hepatoid adenocarcinoma',
      'Large cell neuroendocrine carcinoma',
      'Mixed adenoneuroendocrine carcinoma',
      'Neuroendocrine carcinoma (poorly differentiated)',
      'Small cell neuroendocrine carcinoma',
      'Squamous cell carcinoma',
      'Tubular (intestinal) adenocarcinoma',
      'Undifferentiated carcinoma',
      'Other',
    ],
    'thyroid cancer': [],
    'uterine sarcoma': [
      'Adenosarcoma',
      'Adenosarcoma with cartilaginous differentiation',
      'Adenosarcoma with osseous differentiation',
      'Adenosarcoma with other heterologous element',
      'Adenosarcoma with rhabdomyoblastic differentiation',
      'Adenosarcoma with sarcomatous overgrowth',
      'Endometrial rhabdomyosarcoma',
      'Endometrial stromal sarcoma',
      'Endometrial stromal sarcoma with glandular elements',
      'Endometrial stromal sarcoma with sex cord elements',
      'Endometrial stromal sarcoma with smooth muscle differentiation',
      'Leiomyosarcoma',
      'Leiomyosarcoma, epithelioid type',
      'Leiomyosarcoma, myxoid type',
      'Malignant perivascular epithelioid cel',
      'Other',
      'Undifferentiated uterine/endometrial sarcoma',
    ],
    'wilms tumor': ['Favorable', 'Unfavorable', 'Unknown'],
    'ampulla of vater': [
      'Adenocarcinoma',
      'Adenocarcinoma with peculiar aspects',
      'Hepatoid carcinoma',
      'Medullary carcinoma',
      'Mixed adenoneuroendocrine carcinoma',
      'Moderately-differentiated neuroendocrine carcinoma',
      'Neuroendocrine carcinoma, large cell',
      'Neuroendocrine carcinoma, small cell',
      'Undifferentiated carcinoma',
      'Well-differentiated neuroendocrine carcinoma',
    ],
  },
  'clinical stage grouping': {
    'breast cancer': [
      'Stage X',
      'Stage Tis',
      'Stage 0',
      'Stage I',
      'Stage IA',
      'Stage IB',
      'Stage II',
      'Stage IIA',
      'Stage IIB',
      'Stage III',
      'Stage IIIA',
      'Stage IIIB',
      'Stage IIIC',
      'Stage IV',
    ],
    'colorectal cancer': [
      'Stage I',
      'Stage IA',
      'Stage IB',
      'Stage II',
      'Stage IIA',
      'Stage IIB',
      'Stage IIC',
      'Stage III',
      'Stage IIIA',
      'Stage IIIB',
      'Stage IIIC',
      'Stage IV',
      'Stage IVA',
      'Stage IVB',
    ],
    'diffuse midline glioma': [],
    'embryonal tumor': [],
    'endometrial cancer': [
      'Stage I',
      'Stage IA',
      'Stage IB',
      'Stage II',
      'Stage III',
      'Stage IIIA',
      'Stage IIIB',
      'Stage IIIC1',
      'Stage IIIC2',
      'Stage IVA',
      'Stage IVB',
    ],
    'esophageal cancer': [
      'Stage 0',
      'Stage I',
      'Stage IA',
      'Stage IB',
      'Stage IC',
      'Stage II',
      'Stage IIA',
      'Stage IIB',
      'Stage IIIA',
      'Stage IIIB',
      'Stage IIIC',
      'Stage IVA',
      'Stage IVB',
    ],
    "ewing's sarcoma": ['Localized', 'Regional spread', 'Distant', 'Unknown'],
    'extrahepatic bile duct cancer': [],
    glioblastoma: [],
    'head and neck cancer': [],
    'head and neck cancer: lip and oral cavity': [
      'Stage 0',
      'Stage I',
      'Stage II',
      'Stage III',
      'Stage IVA',
      'Stage IVB',
      'Stage IVC',
    ],
    'head and neck cancer: pharynx': [
      'Stage 0',
      'Stage I',
      'Stage II',
      'Stage III',
      'Stage IVA',
      'Stage IVB',
      'Stage IVC',
    ],
    'head and neck cancer: larynx': [
      'Stage 0',
      'Stage I',
      'Stage II',
      'Stage III',
      'Stage IVA',
      'Stage IVB',
      'Stage IVC',
    ],
    'head and neck cancer: paranasal sinuses and nasal cavity': [
      'Stage 0',
      'Stage I',
      'Stage II',
      'Stage III',
      'Stage IVA',
      'Stage IVB',
      'Stage IVC',
    ],
    'head and neck cancer: salivary glands': [
      'Stage 0',
      'Stage I',
      'Stage II',
      'Stage III',
      'Stage IVA',
      'Stage IVB',
      'Stage IVC',
    ],
    'hematologic cancer': [],
    hepatoblastoma: [
      'Pretext stage I',
      'Pretext stage II',
      'Pretext stage III',
      'Pretext stage IV',
    ],
    'hepatocellular carcinoma': [
      'Stage IA',
      'Stage IB',
      'Stage II',
      'Stage IIIA',
      'Stage IIIB',
      'Stage IV',
      'Stage IVA',
      'Stage IVB',
    ],
    'intrahepatic bile duct cancer': [
      'Stage IA',
      'Stage IB',
      'Stage II',
      'Stage IIIA',
      'Stage IIIB',
      'Stage IV',
      'Stage IVA',
      'Stage IVB',
    ],
    'low grade glioma': [],
    'lung cancer': [
      'Stage 0',
      'Stage IA',
      'Stage IA1',
      'Stage IA2',
      'Stage IA3',
      'Stage IB',
      'Stage II',
      'Stage IIA',
      'Stage IIB',
      'Stage III',
      'Stage IIIA',
      'Stage IIIB',
      'Stage IIIC',
      'Stage IV',
      'Stage IVA',
      'Stage IVB',
    ],
    medulloblastoma: [],
    melanoma: [
      'Stage 0',
      'Stage IA',
      'Stage IB',
      'Stage IIA',
      'Stage IIB',
      'Stage IIC',
      'Stage IIIA',
      'Stage IIIB',
      'Stage IIIC',
      'Stage IIID',
      'Stage IV',
    ],
    neuroblastoma: ['INRGSS L1', 'INRGSS L2', 'INRGSS M', 'INRGSS MS'],
    osteosarcoma: ['Localized', 'Certain metastatic', 'Possible metastatic', 'Unknown'],
    other: [],
    'ovarian cancer': [
      'I',
      'IA',
      'IB',
      'IC',
      'II',
      'IIA',
      'IIB',
      'IIIA1',
      'IIIA2',
      'IIIB',
      'IIIC',
      'IV',
      'IVA',
      'IVB',
    ],
    'pancreatic cancer': [
      'Stage 0',
      'Stage I',
      'Stage IA',
      'Stage IB',
      'Stage IIA',
      'Stage IIB',
      'Stage III',
      'Stage IV',
    ],
    'pediatric hepatocellular carcinoma': [
      'Pretext stage I',
      'Pretext stage II',
      'Pretext stage III',
      'Pretext stage IV',
    ],
    'prostate cancer': [
      'Stage I',
      'Stage II',
      'Stage IIA',
      'Stage IIB',
      'Stage IIC',
      'Stage III',
      'Stage IIIA',
      'Stage IIIB',
      'Stage IIIC',
      'Stage IV',
      'Stage IVA',
      'Stage IVB',
    ],
    'rare cancers': ['Stage 0', 'Stage I', 'Stage II', 'Stage III', 'Stage IV'],
    'kidney cancer': ['Stage I,', 'Stage II', 'Stage III', 'Stage IV'],
    rhabdomyosarcoma: ['1', '2', '3', '4'],
    'stomach cancer': [
      'Stage 0',
      'Stage I',
      'Stage IA',
      'Stage IB',
      'Stage II',
      'Stage IIA',
      'Stage IIB',
      'Stage III',
      'Stage IIIA',
      'Stage IIIB',
      'Stage IIIC',
      'Stage IV',
    ],
    'thyroid cancer': [],
    'uterine sarcoma': [
      'Stage I',
      'Stage IA',
      'Stage IB',
      'Stage II',
      'Stage III',
      'Stage IIIA',
      'Stage IIIB',
      'Stage IIIC1',
      'Stage IIIC2',
      'Stage IVA',
      'Stage IVB',
    ],
    'wilms tumor': [
      'Stage I/y-stage I',
      'Stage II/y-stage II',
      'Stage III/y-stage III',
      'Stage IV',
    ],
    'ampulla of vater': ['0', 'IA', 'IB', 'IIA', 'IIB', 'IIIA', 'IIIB', 'IV'],
  },
  'site of sample acquisition': {
    'breast cancer': [
      'Right breast',
      'Left breast',
      'Lung',
      'Bone',
      'Liver',
      'Brain',
      'Lymph node',
      'Skin',
      'Other',
    ],
    'colorectal cancer': [
      'Cecum',
      'Ileocecal valve',
      'Sigmoid colon',
      'Splenic flexure',
      'Ascending colon',
      'Ascending colon hepatic flexure',
      'Descending colon',
      'Transverse colon',
      'Rectum',
      'Rectosigmoid junction',
      'Liver',
      'Brain',
      'Lung',
      'Peritoneum',
      'Lymph node',
      'Other',
    ],
    'diffuse midline glioma': ['Brain', 'Other', 'Unknown'],
    'embryonal tumor': [
      'Ascites',
      'Bone',
      'Bone marrow',
      'Brain',
      'Cerebrospinal fluid (CSF)',
      'Liver',
      'Lung',
      'Lymph node',
      'Pleural effusion',
      'Pleural nodules',
      'Soft tissue',
      'Spinal Cord',
      'Other',
      'Unknown',
    ],
    'endometrial cancer': [
      'Adenomyosis',
      'Endometrial polyp',
      'Endometrium',
      'Lower uterine segment',
      'Other ',
    ],
    'esophageal cancer': [
      'Esophagus',
      'Esophagus - proximal third',
      'Esophagus - middle third',
      'Esophagus - distal third',
      'Gastroesophageal junction',
      'Bone',
      'Brain',
      'Liver',
      'Lung',
      'Lymph node',
      'Other',
    ],
    "ewing's sarcoma": [
      'Ascites',
      'Bone',
      'Bone marrow',
      'Chest Wall',
      'Lower extremity',
      'Lung',
      'Lymph node',
      'Pelvis',
      'Soft tissue',
      'Spine',
      'Upper extremity',
      'Other',
    ],
    'extrahepatic bile duct cancer': [],
    glioblastoma: ['Brain', 'Other', 'Unknown'],
    'head and neck cancer': [],
    'head and neck cancer: lip and oral cavity': [
      'Alveolar process, mandibular',
      'Alveolar process, maxillary ',
      'Anterior floor of mouth',
      'Anterior two-thirds of tongue',
      'Buccal mucosa',
      'Commissure of lip',
      'Dorsal surface of tongue',
      'External lower lip',
      'External upper lip',
      'Floor of mouth',
      'Hard palate',
      'Lateral border of tongue',
      'Lower gingiva',
      'Mandible',
      'Maxilla',
      'Mucosa of lower lip',
      'Mucosa of upper lip',
      'Other',
      'Retromolar area',
      'Upper gingiva',
      'Ventral surface of tongue',
      'Vestibule of mouth, mandibular',
      'Vestibule of mouth, maxillary',
    ],
    'head and neck cancer: pharynx': [
      'Base of tongue, including lingual tonsil',
      'Nasopharyngeal tonsils (adenoids)',
      'Other',
      'Palatine tonsil',
      'Pharyngeal wall (posterior and/or lateral)',
      'Pharyngeal wall (posterior)',
      'Piriform sinus',
      'Postcricoid',
      'Soft palate',
      'Uvula',
    ],
    'head and neck cancer: larynx': [
      'Anterior commissure of glottis',
      'Aryepiglottic folds',
      'Arytenoid(s)',
      'Epiglottis, laryngeal aspect',
      'Epiglottis, lingual aspect',
      'False vocal cord',
      'Glottis, with subglottic extension',
      'Larynx ventricle',
      'Other',
      'Posterior commissure of glottis',
      'Subglottis',
      'True vocal cord',
    ],
    'head and neck cancer: paranasal sinuses and nasal cavity': [
      'Nasal cavity, NOS',
      'Nasal floor',
      'Nasal lateral wall',
      'Nasal septum',
      'Nasal vestibule',
      'Other',
      'Paranasal sinus(es), ethmoid',
      'Paranasal sinus(es), frontal',
      'Paranasal sinus(es), maxillary',
      'Paranasal sinus(es), sphenoid',
    ],
    'head and neck cancer: salivary glands': [
      'Minor gland(s)',
      'Other',
      'Parotid gland, deep lobe',
      'Parotid gland, superficial lobe',
      'Parotid gland, total',
      'Sublingual gland',
      'Submandibular gland',
    ],
    'hematologic cancer': [],
    hepatoblastoma: ['Ascites', 'Bone', 'Brain', 'Liver', 'Lung', 'Lymph node', 'Other'],
    'hepatocellular carcinoma': [
      'Liver',
      'Common bile duct',
      'Common hepatic duct',
      'Lung',
      'Bone',
      'Brain',
      'Other',
    ],
    'intrahepatic bile duct cancer': [
      'Liver',
      'Common bile duct',
      'Common hepatic duct',
      'Lung',
      'Bone',
      'Brain',
      'Other',
    ],
    'low grade glioma': ['Brain', 'Spinal cord', 'Other', 'Unknown'],
    'lung cancer': [
      'Right upper lobe lung',
      'Right middle lobe lung',
      'Right lower lobe lung',
      'Left upper lobe lung',
      'Left lower lobe lung',
      'Bronchus',
      'Adrenal gland',
      'Bone',
      'Brain',
      'Liver',
      'Lymph node',
      'Other',
    ],
    medulloblastoma: [
      'Ascites',
      'Bone',
      'Bone marrow',
      'Cerebrospinal fluid (CSF)',
      'Brain',
      'Liver',
      'Lung',
      'Lymph node',
      'Pleural effusion',
      'Pleural nodules',
      'Soft tissue',
      'Spinal Cord',
      'Other',
      'Unknown',
    ],
    melanoma: [
      'Skin',
      'Lymph node(s)',
      'Soft tissue',
      'Bone',
      'Lung',
      'Liver',
      'Bowel',
      'Other organs',
    ],
    neuroblastoma: [
      'Abdominal/non-adrenal',
      'Adrenal',
      'Ascites',
      'Bone',
      'Bone marrow',
      'Extra-adrenal',
      'Lung',
      'Lymph node',
      'Neck',
      'Posterior mediastinum',
      'Other',
    ],
    osteosarcoma: [
      'Ascites',
      'Bone',
      'Lower extremity',
      'Lung',
      'Lymph node',
      'Mediastinum',
      'Pelvis',
      'Pleura',
      'Spine',
      'Upper extremity',
      'Other',
    ],
    other: [],
    'ovarian cancer': [
      'Ascites',
      'Left fallopian tube',
      'Left ovary',
      'Left tubo-ovarian',
      'Liver',
      'Lung',
      'Omentum',
      'Peritoneum',
      'Right fallopian tube',
      'Right ovary',
      'Right tubo-ovarian',
      'Other',
    ],
    'pancreatic cancer': [
      'Pancreatic head',
      'Pancreatic body',
      'Pancreatic tail',
      'Liver',
      'Lung',
      'Lymph node',
      'Peritoneum',
      'Other',
    ],
    'pediatric hepatocellular carcinoma': [
      'Ascites',
      'Bone',
      'Brain',
      'Liver',
      'Lung',
      'Lymph node',
      'Other',
    ],
    'prostate cancer': ['Prostate', 'Other'],
    'rare cancers': [
      'Brain',
      'Pleural cavity',
      'Rectosigmoid junction',
      'Gallbladder',
      'Cecum',
      'Ascending colon',
      'Extrahepatic bile duct',
      'Lymph node(s)',
      'Transverse colon',
      'Sigmoid colon',
      'Ileum',
    ],
    'kidney cancer': ['Adrenal Gland', 'Kidney', 'Lymph node', 'Tumor thrombus', 'Other'],
    rhabdomyosarcoma: [
      'Abdominal cavity',
      'Ascites',
      'Biliary tract/liver',
      'Bladder',
      'Bone',
      'Bone marrow',
      'Head and neck (non-PM)',
      'Limb',
      'Lung',
      'Lymph node',
      'Orbit',
      'Parameningeal',
      'Pleura',
      'Prostate',
      'Retroperitoneum',
      'Testis',
      'Other',
    ],
    'stomach cancer': [
      'Antrum',
      'Body',
      'Fundus',
      'Gastroesophageal Junction',
      'Pylorus',
      'Stomach (NOS)',
      'Ascites',
      'Liver',
      'Lung',
      'Lymph node',
      'Other',
    ],
    'thyroid cancer': [],
    'uterine sarcoma': [
      'Adenomyosis',
      'Endometrial polyp',
      'Endometrium',
      'Lower uterine segment',
      'Other ',
    ],
    'wilms tumor': ['Abdomen', 'Ascites', 'Kidney', 'Lung', 'Lymph node', 'Other'],
    'ampulla of vater': [
      'Ampulla of Vater',
      'Bone',
      'Brain',
      'Kidney',
      'Liver',
      'Lung',
      'Lymph node',
      'Peritoneum',
      'Other',
    ],
  },
  'tumor histological grade': {
    'breast cancer': [
      'G1',
      'G2',
      'G3',
      'GX',
      'GB',
      'Well differentiated',
      'Moderately differentiated',
      'Poorly differentiated',
      'Unknown',
    ],
    'colorectal cancer': ['G1', 'G2', 'G3', 'G4', 'GX', 'GB'],
    'diffuse midline glioma': ['G4'],
    'embryonal tumor': [],
    'endometrial cancer': [
      'Grade 1 ',
      'Grade 2',
      'Grade 3',
      'Other ',
      'Cannot be assessed',
      'Not applicable',
      'I',
      'IA',
      'IB',
      'IC',
      'II',
      'IIA',
      'IIB',
      'III',
      'IIIA',
      'IIIB',
      'IIIC',
      'IIIC1',
      'IIIC2',
      'IV',
      'IVA',
      'IVB',
    ],
    'esophageal cancer': ['G1', 'G2', 'G3', 'GX', 'GB'],
    "ewing's sarcoma": [],
    'extrahepatic bile duct cancer': [],
    glioblastoma: [],
    'head and neck cancer': [],
    'head and neck cancer: lip and oral cavity': [
      'G1: Well differentiated',
      'G2: Moderately differentiated',
      'G3: Poorly differentiated',
      'GX: Cannot be assessed',
      'Low grade',
      'Intermediate grade',
      'High grade',
    ],
    'head and neck cancer: pharynx': [
      'G1: Well differentiated',
      'G2: Moderately differentiated',
      'G3: Poorly differentiated',
      'GX: Cannot be assessed',
      'Low grade',
      'Intermediate grade',
      'High grade',
    ],
    'head and neck cancer: larynx': [
      'G1: Well differentiated',
      'G2: Moderately differentiated',
      'G3: Poorly differentiated',
      'GX: Cannot be assessed',
      'Low grade',
      'Intermediate grade',
      'High grade',
    ],
    'head and neck cancer: paranasal sinuses and nasal cavity': [
      'G1: Well differentiated',
      'G2: Moderately differentiated',
      'G3: Poorly differentiated',
      'GX: Cannot be assessed',
      'Low grade',
      'Intermediate grade',
      'High grade',
    ],
    'head and neck cancer: salivary glands': ['Low grade', 'Intermediate grade', 'High grade'],
    'hematologic cancer': [],
    hepatoblastoma: [],
    'hepatocellular carcinoma': ['G1', 'G2', 'G3', 'G4', 'GX', 'GB'],
    'intrahepatic bile duct cancer': ['G1', 'G2', 'G3', 'GX', 'GB'],
    'low grade glioma': [],
    'lung cancer': ['G1', 'G2', 'G3', 'GX', 'GB'],
    medulloblastoma: [],
    melanoma: [],
    neuroblastoma: [],
    osteosarcoma: [],
    other: [],
    'ovarian cancer': [
      'Low grade',
      'High grade',
      'Not applicable',
      'G1',
      'G2',
      'G3',
      'I',
      'IA',
      'IB',
      'IC',
      'IC1',
      'IC2',
      'IC3',
      'II',
      'IIA',
      'IIB',
      'III',
      'IIIA',
      'IIIA1',
      'IIIA1(i)',
      'IIIA1(ii)',
      'IIIA2',
      'IIIB',
      'IIIC',
      'IV',
      'IVA',
      'IVB',
    ],
    'pancreatic cancer': ['G1', 'G2', 'G3', 'GX', 'GB'],
    'pediatric hepatocellular carcinoma': [],
    'prostate cancer': [
      'Cannot be assessed',
      'Grade Group 1',
      'Grade Group 2',
      'Grade Group 3',
      'Grade Group 4',
      'Grade Group 5',
      'Not applicable',
    ],
    'rare cancers': [`GX`, `G1`, `G2`, `G3`, `G4`],
    'kidney cancer': [],
    rhabdomyosarcoma: [],
    'stomach cancer': ['G1', 'G2', 'G3', 'GX', 'GB'],
    'thyroid cancer': [],
    'uterine sarcoma': [
      'I',
      'IA',
      'IB',
      'IC',
      'II',
      'IIA',
      'IIB',
      'III',
      'IIIA',
      'IIIB',
      'IIIC',
      'IIIC1',
      'IIIC2',
      'IV',
      'IVA',
      'IVB',
      'Not applicable',
      'Low grade',
      'High grade',
      'With sarcomatous overgrowth',
      'Cannot be assessed',
    ],
    'wilms tumor': [],
    'ampulla of vater': ['G1', 'G2', 'G3', 'GX', 'GB'],
  },
};

// One of options for Yup and front-end components
export const clinicalTumorDiagnosis = [
  'Ampulla of Vater',
  'Breast cancer',
  'Colorectal cancer',
  'Diffuse midline glioma',
  'Embryonal tumor',
  'Endometrial cancer',
  'Esophageal cancer',
  'Extrahepatic bile duct cancer',
  "Ewing's sarcoma",
  'Glioblastoma',
  'Head and neck cancer',
  'Head and neck cancer: Lip and oral cavity',
  'Head and neck cancer: Pharynx',
  'Head and neck cancer: Larynx',
  'Head and neck cancer: Paranasal sinuses and nasal cavity',
  'Head and neck cancer: Salivary glands',
  'Hematologic cancer',
  'Hepatoblastoma',
  'Hepatocellular carcinoma',
  'Intrahepatic bile duct cancer',
  'Low grade glioma',
  'Lung cancer',
  'Medulloblastoma',
  'Melanoma',
  'Neuroblastoma',
  'Osteosarcoma',
  'Other',
  'Ovarian cancer',
  'Pancreatic cancer',
  'Pediatric hepatocellular carcinoma',
  'Prostate cancer',
  'Rare cancers',
  'Kidney cancer',
  'Rhabdomyosarcoma',
  'Stomach cancer',
  'Thyroid cancer',
  'Uterine sarcoma',
  'Wilms tumor',
];

export const modelType = [
  '3-D: Organoid',
  '3-D: Other (e.g. neurosphere, air-liquid interface, etc.)',
  '2-D: Conditionally reprogrammed cells',
  '2-D: Adherent',
  '2-D: Suspension',
  'Mixed adherent and suspension',
  'Other',
];

export const molecularCharacterizations = [
  'WGS of parent tumor',
  'WGS of normal',
  'WGS of model',
  'WXS of parent tumor',
  'WXS of normal',
  'WXS of model',
  'DNA Methylation of parent tumor',
  'DNA Methylation of normal',
  'DNA Methylation of model',
  'RNA-seq of parent tumor',
  'RNA-seq of model',
  'Proteomics of parent tumor',
  'Proteomics of normal',
  'Proteomics of model',
];

export const splitRatio = ['1:2', '1:4', '1:8'];

export const gender = ['Female', 'Male', 'Unspecified', 'Unknown'];

export const race = [
  'American Indian or Alaskan Native',
  'Asian',
  'Black or African American',
  'Native Hawaiian or other Pacific Islander',
  'White',
  'Not Reported',
  'Unknown',
];

export const neoadjuvantTherapy = [
  'No',
  'Yes, both radiation and pharmaceutical treatment',
  'Yes, pharmaceutical treatment',
  'Yes, radiation',
  'Unknown',
];

export const diseaseStatus = [
  'No evidence of disease',
  'Stable disease',
  'Progressive disease',
  'Unknown',
];

export const vitalStatus = ['Alive', 'Dead', 'Lost to followup'];

export const therapy = [
  'Cytotoxic chemotherapy',
  'Targeted therapy (small molecule inhibitors and targeted antibodies)',
  'Immunotherapy (cellular and immune checkpoint)',
  'Hormonal therapy',
  'Radiation therapy',
  'Surgery',
  'Other',
  'None',
];

export const variantTypes = ['Clinical', 'Histopathological Biomarker'];

export const variantAssessmentType = [
  'ISH/CISH/FISH',
  'IHC',
  'Pyrosequencing/methylation-specific PCR',
  'IHC',
  'PCR',
];

export const variantExpressionLevel = [
  'Amplified',
  'Not amplified',
  'Equivocal',
  'Methylated',
  'Unmethylated',
  'Partially methylated',
  'Indeterminate',
  'Not available',
  'Negative',
  'Positive',
  'Not done',
  'Unknown',
  'Indeterminate',
  'Evidence of MMR mutation by sequencing',
  'Evidence of MMR protein loss by IHC',
  'Evidence of MMR loss by hypermutation phenotype (>10 mutations/Mb)',
  'No evidence of MMR alteration',
  'Allred score 0',
  'Allred score 1',
  'Allred score 2',
  'Allred score 3',
  'Allred score 4',
  'Allred score 5',
  'Allred score 6',
  'Allred score 7',
  'Allred score 8',
  'Low',
  'High',
  'Stable',
  'Normal',
  'Abnormal strong diffuse overexpression (>90%)',
  'Abnormal null expression (complete loss of expression)',
  'Cannot be determined',
  'Yes',
  'No',
  'MLH1 promoter hypermethylation present',
  'MLH1 promoter hypermethylation absent',
  'MLH1 promoter hypermethylation not assessed',
];

export const tissueTypes = ['Primary', 'Metastasis', 'Recurrent', 'Pre-malignant'];
