import { BaseSvg } from 'icons';

const QuestionMarkIcon = ({ height = '16px', width = '16px', ...props }) =>
  BaseSvg({
    alt: 'Question Mark Icon',
    svg: `<svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><mask id="a" fill="#fff"><path d="m0 0h16v16h-16z" fill="#fff" fill-rule="evenodd"/></mask><path d="m10.56 6.414c-.091.295-.25.564-.464.786-.245.245-.513.465-.8.658-.16.11-.302.225-.453.34-.136.113-.238.26-.296.428-.032.086-.069.172-.102.259-.085.235-.307.393-.557.395-.138.015-.278-.007-.405-.064-.195-.095-.33-.28-.363-.494-.051-.297.018-.602.192-.848.138-.203.308-.382.502-.532.21-.17.426-.334.64-.505.178-.136.327-.307.439-.501.177-.337.133-.748-.112-1.04-.127-.138-.296-.232-.48-.266-.246-.057-.502-.053-.746.013-.253.07-.463.244-.577.48-.076.141-.141.285-.212.427-.051.108-.121.206-.206.29-.149.13-.345.194-.542.174-.12-.003-.238-.035-.343-.092-.197-.103-.32-.306-.32-.528-.008-.354.105-.7.32-.98.249-.336.58-.604.96-.777.287-.137.595-.225.91-.263l.18-.017h.32l.173.016c.427.027.846.135 1.233.32.346.159.64.411.851.728.161.25.26.533.29.829.043.254.032.514-.032.764zm-2.56 5.81-.078.016h-.16c-.048-.01-.096-.018-.143-.03-.473-.124-.757-.609-.633-1.082.124-.474.608-.757 1.082-.633.474.123.757.608.633 1.082-.087.333-.36.585-.698.647zm0-12.224c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8z" fill="#b2b7c1" fill-rule="evenodd" mask="url(#a)" transform="translate(.384 .131)"/></svg>`,
    height,
    width,
    ...props,
  });

export default QuestionMarkIcon;
