import React from 'react';
import { InlineSvg } from 'icons';

const UndoIcon = ({
  fill = 'currentColor',
  width = '12px',
  height = '12px',
  viewBox = '0 0 20 16',
  ...props
}) =>
  InlineSvg({
    alt: 'Undo Icon',
    viewBox,
    width,
    height,
    path: (
      <path
        d="m4.0718259 5.13419242c-.21027027.20972973-.13729729.38.16216217.38h9.28054053c3.2837838 0 5.9454054 2.66162162 5.9454054 5.94594598 0 3.2854054-2.6583784 5.9459459-5.9454054 5.9459459h-11.35135135c-.59729729 0-1.08162162-.48-1.08162162-1.0810811 0-.5967567.48756757-1.0810811 1.08162162-1.0810811h11.35135135c2.0924324 0 3.7832432-1.6924324 3.7832432-3.7837837 0-2.09027031-1.6935135-3.78378382-3.7832432-3.78378382h-9.28054053c-.29945946 0-.36756757.17513513-.16216217.38l1.28918919 1.28972973c.42162162.42108108.42378379 1.10216219-.00162162 1.52702699-.42162162.4221622-1.1027027.425946-1.53027027-.001081l-3.51027027-3.51081086c-.42324324-.42324324-.42702703-1.10432432 0-1.53189189l3.51027027-3.51027027c.42324324-.42324324 1.10540541-.42702703 1.53027027-.00162162.4227027.42216216.42162162 1.10756757.00162162 1.52756757z"
        fillRule="evenodd"
        transform="translate(0 -2)"
      />
    ),
    fill,
    ...props,
  });

export default UndoIcon;
