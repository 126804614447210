import { BaseSvg } from 'icons';

const CrossCircleIcon = ({ fill = '#B2B7C1', width = '21px', height = '20px', ...props }) =>
  BaseSvg({
    alt: 'Close Icon',
    width,
    height,
    svg: `<svg viewBox="0 0 21 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M13.8857143,10.9618105 L10.9714286,10.9618105 L10.9714286,13.8953324 C10.9714286,14.4243281 10.5342857,14.8571429 10,14.8571429 C9.46571429,14.8571429 9.02857143,14.4243281 9.02857143,13.8953324 L9.02857143,10.9618105 L6.11428571,10.9618105 C5.58,10.9618105 5.14285714,10.5289958 5.14285714,10 C5.14285714,9.47100424 5.58,9.03818953 6.11428571,9.03818953 L9.02857143,9.03818953 L9.02857143,6.10466761 C9.02857143,5.57567185 9.46571429,5.14285714 10,5.14285714 C10.5342857,5.14285714 10.9714286,5.57567185 10.9714286,6.10466761 L10.9714286,9.03818953 L13.8857143,9.03818953 C14.42,9.03818953 14.8571429,9.47100424 14.8571429,10 C14.8571429,10.5289958 14.42,10.9618105 13.8857143,10.9618105 M10,0 C4.47619048,0 0,4.47619048 0,10 C0,15.5238095 4.47619048,20 10,20 C15.5238095,20 20,15.5238095 20,10 C20,4.47619048 15.5238095,0 10,0" id="Fill-1" transform="translate(10.000000, 10.000000) rotate(45.000000) translate(-10.000000, -10.000000)" fill="${fill}"></path></svg>`,
    ...props,
  });

export default CrossCircleIcon;
