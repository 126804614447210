import React from 'react';
import { InlineSvg } from 'icons';

const DownloadIcon = ({
  fill = 'currentColor',
  width = '12px',
  height = '12px',
  viewBox = '0 0 20 20',
  ...props
}) =>
  InlineSvg({
    alt: 'Download Icon',
    viewBox,
    width,
    height,
    path: (
      <path
        d="m1.32009901 17.1617822h17.16178219c.7291089 0 1.32.5908911 1.32 1.32 0 .7293069-.5908911 1.320198-1.32 1.320198h-17.16178219c-.72910891 0-1.32019802-.5908911-1.32019802-1.320198 0-.7291089.59108911-1.32 1.32019802-1.32zm4.93037624-8.87000002 2.23168317 2.22673272v-9.00693074c0-.77405941.62930693-1.40158416 1.40594059-1.40158416.77643569 0 1.40574259.62811881 1.40574259 1.40158416v9.03247524l2.2576237-2.25227722c.5489109-.54772277 1.4390099-.54772277 1.9883169 0 .5489109.54752475.5493069 1.43485148-.000198 1.98297032l-4.9752476 4.9627723c-.3661386.3651485-.96039601.3653465-1.32693066 0l-4.97524752-4.9627723c-.5489109-.54752478-.54910892-1.43524755 0-1.98297032.54910891-.54772277 1.43881188-.54811881 1.98831683 0z"
        fillRule="evenodd"
      />
    ),
    fill,
    ...props,
  });

export default DownloadIcon;
