import { BaseSvg } from 'icons';

const PencilIcon = ({ fill = '#900000', ...props }) =>
  BaseSvg({
    alt: 'Edit Icon',
    svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90"><defs><style>.cls-1{fill:${fill};}</style></defs><path class="cls-1" d="M45,0A45,45,0,1,0,90,45,45.05,45.05,0,0,0,45,0Zm0,86.54A41.54,41.54,0,1,1,86.54,45,41.59,41.59,0,0,1,45,86.54Z"/><path class="cls-1" d="M65.58,27.39l-3-3a6.31,6.31,0,0,0-8.92,0L26.94,51.17a1.26,1.26,0,0,0-.35.6l-4,13.87A1.41,1.41,0,0,0,24,67.43a1.32,1.32,0,0,0,.39-.06l13.87-4a1.36,1.36,0,0,0,.61-.36L65.58,36.31a6.3,6.3,0,0,0,0-8.92ZM37.11,60.82,26,64l3.17-11.1L50.72,31.35l7.93,7.93ZM63.6,34.32l-3,3L52.7,29.37l3-3a3.5,3.5,0,0,1,5,0l3,3a3.45,3.45,0,0,1,1,2.48A3.48,3.48,0,0,1,63.6,34.32Z"/></svg>`,
    ...props,
  });

export default PencilIcon;
